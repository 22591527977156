/* globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import "antd/dist/reset.css";

@layer utilities {
  .toolbar-wrapper {
    position: fixed;
    bottom: 32px;
    left: 50%;
    padding: 0px 24px;
    color: #fff;
    font-size: 20px;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 100px;
    transform: translateX(-50%);
  }

  .toolbar-wrapper .anticon {
    padding: 12px;
    cursor: pointer;
  }

  .toolbar-wrapper .anticon[disabled] {
    cursor: not-allowed;
    opacity: 0.3;
  }

  .toolbar-wrapper .anticon:hover {
    opacity: 0.3;
  }
}

/* // fix Tailwind CSS border styles,form Tailwind CSS's preflight */
*,
::before,
::after {
  /* box-sizing: border-box; 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: theme("borderColor.DEFAULT", currentColor); /* 2 */
}

::before,
::after {
  --tw-content: "";
}
